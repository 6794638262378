import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 1000);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 rounded-lg shadow-2xl text-black bg-slate-400 m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>Mentor Bridge!</b>
        </p>
        <p className="my-3">
          At Mentor Bridge, our mission is to connect mentors and mentees in a
          meaningful way to foster personal and professional growth. We provide
          a platform that facilitates mentorship relationships, offering support
          and guidance to help individuals achieve their goals.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in 2024, Mentor Bridge was created with the vision of bridging
          the gap between experienced professionals and those seeking guidance.
          We believe that mentorship is a powerful tool for development and
          success, and we are dedicated to making it accessible to everyone.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team is made up of experienced mentors, industry professionals,
          and support staff who are passionate about mentorship and personal
          development. We work together to ensure that our platform provides
          high-quality, effective connections between mentors and mentees.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Mentorship Matching:</b> Connect with experienced mentors in your
            field to receive personalized guidance and support.
          </li>
          <li className="my-2">
            <b>Resources and Tools:</b> Access a variety of resources and tools
            designed to enhance the mentoring experience.
          </li>
          <li className="my-2">
            <b>Support Services:</b> Receive assistance and support to help you
            navigate your mentorship journey.
          </li>
        </ul>

        <h6 className="font-bold">Get In Touch</h6>
        <p className="my-2">
          Thank you for choosing Mentor Bridge. We are excited to support your
          growth and success through meaningful mentorship.
        </p>
      </section>
    </>
  );
}
