import { Link } from "react-router-dom";
import React from "react";

export default function Navbar() {
  return (
    <header className="focus:ring-4 focus:outline-none p-2 container mx-auto my-4 rounded-full bg-slate-900 text-white shadow-lg shadow-slate-300 z-10">
      <nav className="border-gray-200 container mx-auto">
        <div className="flex flex-wrap items-center justify-between md:justify-center mx-auto px-4">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            {/* <h1 className="font-extrabold text-center text-5xl">Web 11</h1> */}
            <img src="/navbar.png" className="h-15" alt="mentorbridge.online" />
          </Link>
        </div>
      </nav>
    </header>
  );
}
