import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    // <div className="bg-sky-200 rounded-2xl shadow-sm shadow-sky-500">
    //   <div className="group hover:-rotate-6 origin-bottom-right duration-500 cursor-pointer group-hover:duration-500 overflow-hidden relative  rounded-2xl shadow-inner shadow-gray-50 flex flex-col justify-around items-center  bg-slate-700 text-gray-50">
    //     <Link to={`/single?slug=${data.slug}`}>
    //       <div className="after:duration-500 before:duration-500 duration-500  group-hover:before:translate-x-11 group-hover:before:-translate-y-11  group-hover:after:translate-x-11 group-hover:after:translate-y-16 after:absolute after:bg-orange-400 after:rounded-full after:-z-10 after:blur-xl after:bottom-32 after:right-16  before:absolute before:h-20 before:bg-sky-400 before:rounded-full before:-z-10 before:blur-xl before:top-20 before:right-16 flex flex-col font-extrabold text-6xl z-10">
    //         <img
    //           src={data.logo}
    //           alt={data.title}
    //           className="w-full h-64 object-fill hover:scale-105"
    //         />
    //       </div>
    //       <div className="flex flex-row justify-center text-lg  font-bold items-center gap-1">
    //         <span>{data.title}</span>
    //       </div>
    //     </Link>
    //   </div>
    // </div>
    <div className="relative group cursor-pointer overflow-hidden duration-500 w-64 h-80 rounded-2xl bg-blue-900 text-gray-50 p-5">
      <div className="">
        <div className="group-hover:scale-110 w-full h-60 bg-blue-400 duration-500">
          <img
            src={data.logo}
            alt={data.title}
            className="w-full h-64 object-fill hover:scale-105"
          />
        </div>
        <div className="absolute w-56 left-0 p-5 -bottom-16 duration-500 group-hover:-translate-y-12">
          <div className="absolute -z-10 left-0 w-64 h-28 opacity-0 duration-500 group-hover:opacity-50 group-hover:bg-blue-900"></div>
          <span className="text-md font-bold text-center">{data.title}</span>
          <p className="group-hover:opacity-100 w-56 duration-500 opacity-0 my-3">
            <Link
              to={`/single?slug=${data.slug}`}
              className="bg-fuchsia-400 focus:ring-0 focus:outline-none focus:ring-red-100 font-bold rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 uppercase"
            >
              Play
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
